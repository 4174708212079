var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "magazineDisplayList" },
    [
      _c("div", { staticClass: "magazineDisplayList__container" }, [
        _c("div", { staticClass: "magazineDisplayList__pageHeadline" }, [
          _vm._v(
            "\n            " + _vm._s(_vm.overrideTopicHeadline) + "\n        "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "articleContainer",
          staticClass: "magazineDisplayList__container",
          attrs: { id: "magazine-article-container" },
        },
        [
          !_vm.showCategoryList
            ? _c(
                "div",
                {
                  staticClass:
                    "magazineDisplayList__categorySwitch magazineDisplayList__categorySwitch--top",
                  on: { click: _vm.onOpenSidebarClick },
                },
                [
                  _c("icon", {
                    attrs: { icon: "categories", color: "company" },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "magazineDisplayList__categorySwitch--text",
                    },
                    [_vm._v(_vm._s(_vm.$t("magazine.list.showCategory")))]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasRecommendedArticles
            ? _c("span", { staticClass: "magazineDisplayList__subHeadline" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("magazine.list.recommendedArticles")) +
                    "\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasRecommendedArticles
            ? _c(
                "div",
                { staticClass: "magazineDisplayList__inner" },
                _vm._l(_vm.recommendedArticles, function (article) {
                  return _c("magazine-list-item", {
                    key: article.title,
                    attrs: { article: article, small: true },
                  })
                }),
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("span", { staticClass: "magazineDisplayList__subHeadline" }, [
            _vm._v(_vm._s(_vm.$t("magazine.list.newArticles"))),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "magazineDisplayList__sort",
              on: {
                click: _vm.onSortArticlesByUid,
                mouseenter: function ($event) {
                  _vm.sortIconHover = true
                },
                mouseleave: function ($event) {
                  _vm.sortIconHover = false
                },
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.sortLabel) +
                  "\n                "
              ),
              _c("icon", {
                attrs: {
                  icon: "sort",
                  filled: _vm.sortIconHover,
                  color: "company",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "magazineDisplayList__inner" },
            [
              _vm.isLoading
                ? _c("default-content-element-loader-component")
                : _vm._e(),
              _vm._v(" "),
              _vm.isLoading ? _c("infinity-loading-spinner") : _vm._e(),
              _vm._v(" "),
              _vm._l(_vm.articles, function (article) {
                return !_vm.isLoading
                  ? _c("magazine-list-item", {
                      key: article.title,
                      attrs: { article: article },
                    })
                  : _vm._e()
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c("infinite-loading", {
            attrs: { identifier: _vm.infiniteId },
            on: { infinite: _vm.onInfinite },
            scopedSlots: _vm._u([
              {
                key: "spinner",
                fn: function () {
                  return [_c("infinity-loading-spinner")]
                },
                proxy: true,
              },
              {
                key: "no-more",
                fn: function () {
                  return [_vm._v(" ")]
                },
                proxy: true,
              },
              {
                key: "no-results",
                fn: function () {
                  return [
                    _c("empty-list-notification", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.$t("magazine.list.noResults")) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showCategoryList
        ? _c("category-list", {
            ref: "categoryList",
            attrs: {
              context: _vm.context,
              "show-close-icon": !_vm.showCategoryList,
              "is-scrolled": _vm.isScrolled,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }