import { render, staticRenderFns } from "./MagazineDisplayList.vue?vue&type=template&id=13df62e2&scoped=true&"
import script from "./MagazineDisplayList.vue?vue&type=script&lang=ts&"
export * from "./MagazineDisplayList.vue?vue&type=script&lang=ts&"
import style0 from "MagazineDisplayList.sass?vue&type=style&index=0&id=13df62e2&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13df62e2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('13df62e2')) {
      api.createRecord('13df62e2', component.options)
    } else {
      api.reload('13df62e2', component.options)
    }
    module.hot.accept("./MagazineDisplayList.vue?vue&type=template&id=13df62e2&scoped=true&", function () {
      api.rerender('13df62e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/ContentElement/Persona/MagazineDisplay/List/MagazineDisplayList.vue"
export default component.exports