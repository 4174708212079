






















































































import {getSize} from '@labor-digital/helferlein/lib/Dom/getSize';
import {HelferleinEventList} from '@labor-digital/helferlein/lib/Events/HelferleinEventList';
import {PlainObject} from '@labor-digital/helferlein/lib/Interfaces/PlainObject';
import {isEmpty} from '@labor-digital/helferlein/lib/Types/isEmpty';
import {isNull} from '@labor-digital/helferlein/lib/Types/isNull';
import {getScrollPos} from '@labor-digital/helferlein/lib/Ui/getScrollPos';
import {destroyStickyElement, stickyElement} from '@labor-digital/helferlein/lib/Ui/stickyElement';
import {ContentElementContext} from '@labor-digital/typo3-vue-framework/lib/Core/Context/ContentElementContext';
import {AppStoreKeys} from '../../../../AppStoreKeys';
import MagazineListItem from '../../../../Component/Magazine/ListItem/MagazineListItem.vue';
import EmptyListNotification from '../../../../Component/Misc/EmptyListNotification/EmptyListNotification.vue';
import Icon from '../../../../Component/Misc/Icon/Icon.vue';
import LinkWrap from '../../../../Component/Misc/Link/LinkWrap/LinkWrap.vue';
import InfinityLoadingSpinner from '../../../../Component/Misc/Spinner/InfinityLoadingSpinner.vue';
import ComponentProxyAwareMixin from '../../../../Mixin/ComponentProxyAwareMixin';
import CategoryList from './CategoryList/CategoryList.vue';

export default {
    name: 'MagazineDisplayList',
    components: {MagazineListItem, InfinityLoadingSpinner, CategoryList, Icon, LinkWrap, EmptyListNotification},
    mixins: [ComponentProxyAwareMixin],
    props: {
        context: null as ContentElementContext
    },
    computed: {
        hasRecommendedArticles(): boolean
        {
            return isNull(this.context.initialQuery.filter.categories) &&
                   !isEmpty(this.recommendedArticles);
        },
        recommendedArticles(): PlainObject
        {
            return this.context.data.get('recommendedArticles', []);
        },
        overrideTopicHeadline(): string
        {
            return this.context.store.get(AppStoreKeys.MAGAZINE_HEADLINE_OVERRIDE,
                this.$t('magazine.list.allArticles'));
        }
    },
    data()
    {
        return {
            categoryBreakpoint: 1750,
            showCategoryList: false,
            articles: this.context.initialState.response.data,
            page: 1,
            infiniteId: 1,
            isScrolled: false,
            isLoading: false,
            sortOrder: '-uid',
            sortLabel: this.$t('magazine.list.sortNew'),
            sortIconHover: false
        };
    },
    methods: {
        onOpenSidebarClick()
        {
            // We have to get a bit creative here to inject the correct context
            // instance into our category list. Therefore we remove the context
            // prop from a cloned category list component and replace it with
            // a computed property that points to the context object of this component.
            const categoryListClone = {...CategoryList, props: {...CategoryList.props}};
            delete categoryListClone.props.context;
            const context = this.context;
            this.context.store.set(AppStoreKeys.SIDEBAR_COMPONENT_LEFT, {
                extends: categoryListClone,
                data()
                {
                    return {
                        context
                    };
                }
            });

        },

        onInfinite(state)
        {
            // Get the pagination information
            const maxPage = this.context.initialState.pagination.pages;
            if (this.page >= maxPage) {
                state.loaded();
                state.complete();
            } else {
                this.page++;
                this.makeRequest().then(() => {
                    state.loaded();
                });
            }
        },
        makeRequest(reset?: boolean, sort?: string): Promise<any>
        {
            if (sort) {
                this.context.initialQuery.sort[0] = sort;
                this.page = 1;
            }

            this.context.initialQuery.page.number = this.page;

            return this.context.appContext.resourceApi.get(this.context.initialType, this.context.initialQuery)
                       .then(result => {
                           // Reset the values if required
                           if (reset === true) {
                               this.articles = [];
                           }

                           // Inject the new result
                           this.articles.push(...result.response.data);
                           this.context.initialState.pagination.pages = result.pagination.pages;
                       })
                       .then(() => {
                           return new Promise(resolve => {
                               this.isScrolled = false;
                               this.isLoading = false;
                               this.$nextTick(() => resolve());
                           });
                       });
        },
        onSortArticlesByUid()
        {
            if (this.sortOrder === '-uid') {
                this.sortOrder = 'uid';
                this.sortLabel = this.$t('magazine.list.sortOld');
            } else {
                this.sortOrder = '-uid';
                this.sortLabel = this.$t('magazine.list.sortNew');
            }

            this.isLoading = true;
            this.makeRequest(true, this.sortOrder);
        },
        onScroll()
        {
            if (!this.showCategoryList) {
                return;
            }
            let scroll = getScrollPos(window);

            const articleContainerHeight = !!this.$refs.articleContainer ?
                getSize(this.$refs.articleContainer).height : 0;
            const categoryContainerHeight = !!this.$refs.categoryList ?
                getSize(this.$refs.categoryList.$el).height : 0;
            this.isScrolled = scroll >= articleContainerHeight - categoryContainerHeight;
        },
        onWindowResize()
        {
            // Make sure to destroy the category list if required
            const showCategoryList = window.innerWidth > this.categoryBreakpoint;
            if (!!this.$refs.categoryList && this.showCategoryList && !showCategoryList) {
                destroyStickyElement(this.$refs.categoryList.$el);
            }

            // Render the category
            const isNowSticky = !this.showCategoryList && showCategoryList;
            this.showCategoryList = showCategoryList;

            // Ignore if the element is already stick
            if (!isNowSticky) {
                return;
            }

            // Wait for the next tick
            this.$nextTick(() => {
                // Ignore if there is no category list now
                if (!this.$refs.categoryList) {
                    return;
                }

                // Make category sticky
                stickyElement(this.$refs.categoryList.$el, {
                    'offset': 121
                });
            });
        }
    },
    mounted(): void
    {
        // Initialize the sticky category if required
        this.onWindowResize();

        this.proxy.bind(
            this.context.appContext.eventEmitter,
            HelferleinEventList.EVENT_SCROLL_THROTTLED,
            this.onScroll);

        this.proxy.bind(
            this.context.appContext.eventEmitter,
            HelferleinEventList.EVENT_RESIZE_THROTTLED,
            this.onWindowResize);
    },
    beforeDestroy()
    {
        if (!!this.$refs.categoryList) {
            destroyStickyElement(this.$refs.categoryList.$el);
        }
    }
};
